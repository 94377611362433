






































































import { Component, Vue } from 'vue-property-decorator';
import UploadButton from '@/components/UploadButton.vue';
import {api} from '@/api';
import {commitAddNotification, commitRemoveNotification} from '@/store/main/mutations';
import {AppNotification} from '@/store/main/state';

@Component({
  components: {UploadButton},
})
export default class AdminDashboard extends Vue {
  public selected: string = 'videos';
  public file: File | { name: string } = { name: '' };
  public importDisabled: boolean = true;
  public importLoading: boolean = false;
  public validateDisabled: boolean = true;
  public validateLoading: boolean = false;
  public videoThumbnailLoaderLoading: boolean = false;
  public videoUrlLoaderLoading: boolean = false;
  public calculateLevelsLoading: boolean = false;
  public calculateDiscoverLoading: boolean = false;
  private notification: AppNotification | null = null;

  public reset() {
    this.validateDisabled = false;
    this.importDisabled = true;
  }

  public async fileChosen(files) {
    this.reset();
    this.file = files[0];
  }
  public async validateFile() {
    this.validateLoading = true;
    if (this.file instanceof File) {
      try {
        const x = await api.validateFile(this.$store.getters.token, this.file, this.selected).then((response) => {
          this.setInfo(`Validated file: ${JSON.stringify(response.data)}`);
          this.importDisabled = false;
        }).catch((error) => {
          this.setError(error.response.data.detail);
        });
      } catch (err) {
        this.setError('Unexpected error validating file.');
      }
    }
    this.validateLoading = false;
  }

  public async importFile() {
    this.importLoading = true;
    if (this.file instanceof File) {
      try {
        await api.uploadFile(this.$store.getters.token, this.file, this.selected).then((response) => {
          this.setSuccess(`Imported file: ${JSON.stringify(response.data)}`);
          this.importDisabled = true;
        }).catch((error) => {
          this.setError(error.response.data.detail);
        });
      } catch (err) {
        this.setError('Unexpected error importing file.');
      }
    }
    this.importLoading = false;
  }
  public async loadVideoThumbnails() {
    await this.simpleApiCall('videoThumbnailLoaderLoading', api.loadVideoThumbnails, 'Loaded video thumbnails');
  }

  public async loadVideoUrls() {
    await this.simpleApiCall('videoUrlLoaderLoading', api.loadVideoUrls, 'Loaded video urls');
  }

  public async calculateLevels() {
    await this.simpleApiCall('calculateLevelsLoading', api.calculateLevels, 'Calculated user levels');
  }

  public async calculateDiscover() {
    await this.simpleApiCall('calculateDiscoverLoading', api.calculateDiscover, 'Calculated new discover videos');
  }

  private async simpleApiCall(loadingVar, apiCall, msgSuccess) {
    // this.$store.commit('commitAddNotification');
    this[loadingVar] = true;
    try {
      await apiCall(this.$store.getters.token).then((response) => {
        if (response.data) {
          this.setSuccess(`${msgSuccess}: ${JSON.stringify(response.data)}`);
        } else {
          this.setSuccess(`${msgSuccess}.`);
        }
      }).catch((error) => {
        this.setError(error.response.data.detail);
      });
    } catch (err) {
      this.setError('Unexpected error.');
    }
    this[loadingVar] = false;
  }

  private setError(msg) {
    this.addNotification(msg, 'error');
  }

  private setInfo(msg) {
    this.addNotification(msg, 'info');
  }

  private setSuccess(msg) {
    this.addNotification(msg, 'success');
  }

  private addNotification(msg, type) {
    this.removeNotification();
    this.notification = {
      content: msg,
      color: type,
    };
    commitAddNotification(this.$store, this.notification);
  }

  private removeNotification() {
    if (this.notification) {
      commitRemoveNotification(this.$store, this.notification);
    }
  }
}
