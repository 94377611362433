







import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component
export default class UploadButton extends Vue {
  @Prop(String) public color: string | undefined;
  @Prop({default: false}) public multiple!: boolean;
  @Emit()
  public files(e): FileList {
    return e.target.files;
  }

  public trigger() {
    (this.$refs.fileInput as HTMLElement).click();
  }
}
